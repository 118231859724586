<template>
  <section class="intro-x my-5">
    <div class="w-full flex items-center mb-5">
      <back-button class="mr-2" />
      <h1 class="font-bold text-lg">
        Formulario solicitud
      </h1>
    </div>

    <form @submit="onSubmit">
      <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 mb-5">
        <!-- branches office -->
        <div class="mb-1">
          <label for="fecha">Tipo Solicitud</label>
          <select
          v-model="model.type"
          name="type"
          class="form-control capitalize"
          @change="selectTipoSolicitud"
          >
            <option :value="null">Seleccionar</option>
            <option :value="1" class="capitalize">
              Mensual
            </option>
            <option :value="2" class="capitalize">
              Extra
            </option>
          </select>
          <messageError :text="errors.type" />
        </div>
      </div>
      <div v-if="!hasCreate" class="mb-3 bg-yellow-50 inline-block p-2 rounded-md ">
        <div class="flex items-center">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="text-yellow-700">
            <path fill="currentColor" d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
          </svg>
          <p class="text-yellow-700 mt-1" >No es posible realizar solicitudes mensuales, el dia de apertura es: <b>{{getParametros.diaApertura}} </b> y el dia de cierre es: <b>{{getParametros.diaCierre}}</b></p>
        </div>
      </div>

      <div v-if="hasCreate" class="w-full">
        <h1 class="font-bold text-lg">
          Productos
        </h1>
      </div>
      <div v-if="hasCreate" class="w-full mb-3">
        <DataTable :value="model.articulos">
          <Column headerStyle="width:50px;">
            <template #body="{ data }">
                <div>
                  <button
                  @click="onRemoveArticle(data)"
                  type="button"
                  class="bg-red-700 text-white rounded-md btn-remover py-1 px-1"
                  >
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                    </svg>
                  </button>
                </div>
            </template>
          </Column>
          <Column header="NOMBRE" field="codeName">
            <template #body="{ data }">
              <div class="p-inputgroup">
                <AutoComplete
                  v-model="data.detail"
                  :suggestions="filteredProducts"
                  @complete="searchProduct($event)"
                  @item-select="selectProduct($event,data._key)"
                  field="codeName"
                  inputClass="form-control"
                >
                  <template #item="{ item }">
                    <div>
                      <span>{{item.codeName}}</span>
                    </div>
                  </template>
                </AutoComplete>
              </div>
              <messageError :text="errors[`articulos[${data._key}].detail`]" />
            </template>
          </Column>
          <Column header="CANTIDAD">
            <template #body="{ data }">
              <div class="p-inputgroup">
                <input @keyup="data.requestAmount=$h.onlyNumber($event.target.value)"
                type="number"
                class="form-control"
                v-model="data.requestAmount">
              </div>
              <messageError :text="errors[`articulos[${data._key}].requestAmount`]" />
            </template>
          </Column>
        </DataTable>
        <messageError :text="errors.articulos" />
        <small class="p-error">{{errorDuplicate}}</small>
      </div>
      <div v-if="hasCreate" class="mb-3">
        <button type="button" class="btn btn-primary mr-2" @click="onAddProduct">
          Agregar
        </button>
      </div>
      <div class="w-full my-5 flex justify-center">
        <button
        v-if="hasCreate"
        type="submit"
        class="btn btn-primary"
        >
          Crear
        </button>
      </div>
    </form>
  </section>
</template>
<script>
import requestsStateUpdateService from '../../../../services/requestsStateUpdateService'
import requestsDetailDelete from '../../../../services/requestsDetailDelete'
import requestsBuscar from '../../../../services/requestsBuscar'
import requestsCrear from '../../../../services/requestsCrear'
import orderCrear from '../../../../services/requestsCrearPedido'
import requestsActualizar from '../../../../services/requestsActualizar'
import findNameArticle from '../../../../services/findNameArticle'
import articleInventaryFindService from '../../../../services/articleInventaryFindService'
import { messageConfirm, messageSuccess } from '../../../../../../../libs/mensajes'
import { useForm, useField } from 'vee-validate'
import { object, array, number, string } from 'yup'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import AutoComplete from 'primevue/autocomplete'
import { useRouter, useRoute } from 'vue-router'
import { onMounted, computed, ref } from 'vue'
import dayjs from 'dayjs'
import useSolicitudesForm from './useSolicitudesForm'

export default {
  name: 'formSolicitud',
  components: {
    DataTable,
    Column,
    AutoComplete
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { fetchParametros, getParametros } = useSolicitudesForm()
    const isEdit = computed(() => {
      if (route.params.id) return true
      return false
    })
    const errorDuplicate = ref('')
    const optiosBranchOffice = ref([])
    const optionsAreas = ref([])
    const filteredProducts = ref([])
    const validationSchema = object().shape({
      type: number().nullable().required().label('tipo solicitud'),
      articulos: array()
      .of(
        object().shape({
          presentation_id: number().nullable(true).required().label('presentación del producto'),
          requestAmount: number().nullable(true).min(1).required().label('cantidad del producto'),
          detail: object().shape({
            name: string().required().nullable().label('nombre del producto')
          }).required().nullable().label('detalles del producto')
        })
      )
      .min(1).label('articulos')
    })
    const { values: model, handleSubmit, handleReset, errors } = useForm({
      validationSchema
    })
    useField('type', null, { initialValue: 1 })
    useField('articulos', null, {
      initialValue: [{ id: null, article_id: null, detail: null, _key: 0 }]
    })
    const vaidateDuplicateArticle = () => {
      var vali = 0
      for (const v of model.articulos) {
        const itemVali = model.articulos.filter(f => f.article_id === v.article_id)
        if (itemVali.length > 1) {
          vali = vali + 1
        }
      }
      errorDuplicate.value = ''
      if (vali) {
        errorDuplicate.value = 'Existen artículos con su presentación duplicados'
        return false
      }
      return true
    }
    const onEditSubmit = handleSubmit((values) => {
      if (!vaidateDuplicateArticle()) return false
      if (isEdit.value) {
        requestsActualizar(route.params.id, model).then(({ status }) => {
          if (status === 200) {
            errorDuplicate.value = ''
            messageSuccess('Solicitud actualizado')
            fetchRequest()
          }
        }).catch(err => {
          console.error(err.message)
        })
        return false
      }
    })
    const onSubmit = handleSubmit((values) => {
      if (!vaidateDuplicateArticle()) return false
      requestsCrear(model).then(({ status, data }) => {
        if (status === 201) {
          const state = model.type === 1 ? 2 : 10
          const justification = model.type === 1 ? 'solicitado a sede' : 'solicitud pendiente'
          requestsStateUpdateService(data.data.id, { state, justification }).then(() => {
            messageConfirm('Desea crear otra Solicitud', 'Solicitud creada', 'success').then(({ isConfirmed }) => {
              if (isConfirmed) {
                errorDuplicate.value = ''
                setTimeout(() => {
                  handleReset()
                  model.articulos = []
                  model.articulos.push({ id: null, detail: null, article_id: null, _key: model.articulos.length })
                  model.date = dayjs().format('YYYY-MM-DD')
                }, 200)
                return false
              }
              router.back()
            })
          }).catch(err => {
            console.error(err.message)
          })
        }
      }).catch(err => {
        console.error(err.message)
      })
    })
    const onAddOrder = () => {
      orderCrear(route.params.id, model).then(({ status, data }) => {
        if (status === 200) {
          messageSuccess('Pedido creado')
          router.back()
        }
      }).catch(err => {
        console.error(err.message)
      })
    }
    const removeDetail = (_key) => {
      model.articulos.splice(_key, 1)
      vaidateDuplicateArticle()
    }
    const onRemoveArticle = (data) => {
      removeDetail(data._key)
      if (data.id) {
        requestsDetailDelete({ id: data.id }).then(res => {
          console.log(res)
          // messageSuccess('Artículo eliminado')
        }).catch(err => {
          console.error(err.message)
        })
      } else {
        // messageSuccess('Artículo eliminado')
      }
      for (const key in model.articulos) {
        model.articulos[key]._key = key
      }
    }
    const onAddProduct = () => {
      model.articulos.push({ id: null, detail: null, article_id: null, _key: model.articulos.length })
    }
    const searchProduct = ({ query }) => {
      if (!query.trim() || query.length < 3 || (model.type !== 1 && model.type !== 2)) {
        filteredProducts.value = []
      } else {
        if (model.type === 1) {
          return findNameArticle({ name: query.trim() }).then(({ data }) => {
            filteredProducts.value = data
          }).catch(err => {
            console.error(err.message)
          })
        }
        return articleInventaryFindService({ name: query.trim() }).then(({ data }) => {
          filteredProducts.value = data
        }).catch(err => {
          console.error(err.message)
        })
      }
    }
    const selectProduct = ({ value }, _key) => {
      model.articulos[_key].article_id = value.id
      model.articulos[_key].presentation_id = value.presentations.id
    }
    const selectTipoSolicitud = (value) => {
      model.articulos = [{ id: null, detail: null, article_id: null, _key: 0 }]
    }
    const fetchRequest = () => {
      return requestsBuscar(route.params.id).then(({ status, data }) => {
        // console.log(data)
        model.type = data.type
        model.date = data.date
        model.branchOfficeId = data.branchOfficeId
        model.areaId = data.areaId
        model.articulos = data.details.map((e, key) => {
          const article = e.articlesPresentation.article

          return {
            _key: key,
            id: e.id,
            presentation_id: e.articlePresentationId,
            article_id: e.articlesPresentation.article_id,
            requestAmount: e.requestAmount,
            detail: { id: article.id, name: article.name, codeName: article.codeName }
          }
        })
      }).catch(err => {
        console.error(err.message)
      })
    }

    const hasCreate = computed(() => {
      if (model.type === 1) {
        const diaHoy = new Date().getDate()
        if (diaHoy >= getParametros.value.diaApertura && diaHoy <= getParametros.value.diaCierre) {
          return true
        }
        return false
      }
      return true
    })

    onMounted(() => {
      fetchParametros()
      if (isEdit.value) {
        fetchRequest()
      } else {
        setTimeout(() => {
          handleReset()
          model.date = dayjs().format('YYYY-MM-DD')
        }, 200)
      }
    })
    return {
      model,
      errors,
      errorDuplicate,
      optiosBranchOffice,
      optionsAreas,
      filteredProducts,
      onRemoveArticle,
      onAddOrder,
      onAddProduct,
      onSubmit,
      onEditSubmit,
      searchProduct,
      selectProduct,
      selectTipoSolicitud,
      isEdit,
      getParametros,
      hasCreate
    }
  }
}
</script>
